import request from '@/utils/request.js';

/**
 * 分页查询 领导交办事项台账
 * @returns {Promise}
 */
 export function pageLedger(params) {
  return request({
    url: '/traffic-construction/matter/pageLedger',
    method: 'get',
    params
  });
}
/**
 * 分页查询 待办事项
 * @returns {Promise}
 */
 export function pageTodo(params) {
  return request({
    url: '/traffic-construction/matter/pageTodo',
    method: 'get',
    params
  });
}
/**
 * 分页查询 已办事项
 * @returns {Promise}
 */
 export function pageDone(params) {
  return request({
    url: '/traffic-construction/matter/pageDone',
    method: 'get',
    params
  });
}
/**
 * 详情
 * @returns {Promise}
 */
export function taskDetail(params) {
  return request({
    url: '/traffic-construction/matter/detail',
    method: 'get',
    params
  });
}
/**
 * 删除领导交办事项
 * @returns {Promise}
 */
export function taskDelete(data) {
  return request({
    url: '/traffic-construction/matter/delete',
    method: 'post',
    data
  });
}
/**
 * 新增责任单位机构树
 * @returns {Promise}
 */
 export function getOrgTree(params) {
  return request({
    url: '/traffic-construction/matter/getOrgTree',
    method: 'get',
    params
  });
}
/**
 * 新增责任人树结构
 * @returns {Promise}
 */
 export function userTree(params) {
  return request({
    url: '/traffic-construction/matter/userTree',
    method: 'get',
    params
  });
}

/**
 * 存为草稿
 * @returns {Promise}
 */
export function addAsDraft(data) {
  return request({
    url: '/traffic-construction/matter/addAsDraft',
    method: 'post',
    data
  });
}
/**
 * 新增提交/草稿编辑提交
 * @returns {Promise}
 */
 export function addAndSubmit(data) {
  return request({
    url: '/traffic-construction/matter/addAndSubmit',
    method: 'post',
    data
  });
}
/**
 * 草稿-直接提交
 * @returns {Promise}
 */
 export function submitAsDraft(data) {
  return request({
    url: '/traffic-construction/matter/submitAsDraft',
    method: 'post',
    data
  });
}
/**
 * 退回时责任人下拉列表
 * @returns {Promise}
 */
 export function responsibleList(params) {
  return request({
    url: '/traffic-construction/matter/responsibleList',
    method: 'get',
    params
  });
}
/**
 * 办理提交
 * @returns {Promise}
 */
 export function OAhandle(data) {
  return request({
    url: '/traffic-construction/matterOp/handle',
    method: 'post',
    data
  });
}
/**
 * 退回提交
 * @returns {Promise}
 */
 export function OAgoBack(data) {
  return request({
    url: '/traffic-construction/matterOp/goBack',
    method: 'post',
    data
  });
}
/**
 * 办结提交
 * @returns {Promise}
 */
 export function OAclose(data) {
  return request({
    url: '/traffic-construction/matterOp/close',
    method: 'post',
    data
  });
}