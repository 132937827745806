<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="show"
      :close-on-click-modal="false"
      @close="handelClose"
      width="960px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        v-loading="dialogLoading"
        class="form-box"
      >
        <el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工作内容" prop="content" :required="!isRead">
                <el-input
                  v-model="ruleForm.content"
                  type="textarea"
                  placeholder="请输入"
                  :maxlength="500"
                  :disabled="isRead"
                  resize="none"
                  :autosize="{ minRows: 2 }"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="办结时限" prop="timeLimit" :required="!isRead">
                <el-date-picker
                  v-model="ruleForm.timeLimit"
                  format="yyyy-MM-dd HH:mm"
                  :editable="false"
                  type="datetime"
                  :picker-options="pickerOptions"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @change="getNow"
                  :disabled="isRead"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="责任单位" prop="responsibleUnit" :required="!isRead">
                <el-cascader
                  v-model="ruleForm.responsibleUnit"
                  :options="orgList"
                  :props="treeOption"
                  clearable
                  :show-all-levels="false"
                  :disabled="isRead"
                ></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="责任人" prop="responsible" :required="!isRead">
                <el-cascader
                  v-model="ruleForm.responsible"
                  :options="userList"
                  :props="treeOption"
                  clearable
                  :show-all-levels="false"
                  :disabled="isRead"
                ></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="附件">
                <upload-multiple-file
                  v-if="!isRead"
                  v-model="ruleForm.annex"
                  :default-list="defaultList"
                  @get-file-data="getFileData"
                ></upload-multiple-file>
                <!-- @get-file-detail="getFileDetail" -->
                <el-table v-if="!!ruleForm.annexList" :data="ruleForm.annexList" border style="width: 100%">
                  <el-table-column label="序号" align="center">
                    <template slot-scope="scope">
                      {{ scope.$index + 1 }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="fileName" label="文件名" align="center"> </el-table-column>
                  <el-table-column prop="id" label="操作" align="center">
                    <template slot-scope="scope">
                      <span class="action-btn" @click="uploadFile(scope.row.id)">下载</span>
                      <!-- <span v-if="!isRead" class="action-btn deletewarning" @click="deleteAnnex(scope.row.id)">删除</span> -->
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注说明" prop="remark">
                <el-input
                  v-model="ruleForm.remark"
                  type="textarea"
                  :placeholder="isRead ? '' : '请输入'"
                  :maxlength="200"
                  :disabled="isRead"
                  resize="none"
                  :autosize="{ minRows: 2 }"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="isRead">
            <el-row>
              <p class="taskInfoP">办理情况</p>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-table :data="ruleForm.records" border style="width: 100%">
                  <el-table-column label="序号" align="center">
                    <template slot-scope="scope">
                      {{ scope.$index + 1 }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="managerName" label="办理人" align="center" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="handleUnitName" label="办理单位" align="center" show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="operational" label="办理类型" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span v-html="transOperational(scope.row.operational)"></span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dealTime" label="办理时间" align="center" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span>{{ scope.row.dealTime.slice(0, scope.row.dealTime.length - 3) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="annexList" label="附件" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.annexList.length"
                        class="action-btn"
                        @click="uploadFilemore(scope.row.annexList)"
                        >下载</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="illustrate" label="办理说明" align="center" show-overflow-tooltip>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </div>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <span v-if="!isRead">
          <el-button @click="handelChanel">取 消</el-button>
          <el-button type="warning" @click="addAsDraft" :loading="btnLoading">存为草稿</el-button>
          <el-button type="primary" @click="handelsure" :loading="btnLoading">提 交</el-button>
        </span>
        <span v-else>
          <span v-if="OAbtnshow">
            <el-button @click="handelChanel">取 消</el-button>
            <el-button v-if="statuType" type="primary" @click="handeltodo" :loading="btnLoading">办 理</el-button>
            <el-button v-if="!statuType" type="danger" @click="handelreturn" :loading="btnLoading">回 退</el-button>
            <el-button v-if="!statuType" type="primary" @click="handelTaskClose" :loading="btnLoading">办 结</el-button>
          </span>
        </span>
      </div>
    </el-dialog>
    <!-- 办理弹窗 -->
    <el-dialog
      width="960px"
      title="办理事项"
      :visible.sync="innerVisible"
      :close-on-click-modal="false"
      @close="handelClose2"
    >
      <el-form
        :model="handelForm"
        :rules="todorules"
        ref="handelForm"
        label-width="150px"
        v-loading="dialogLoading"
        class="form-box"
      >
        <el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工作内容" prop="illustrate" required>
                <el-input
                  v-model="handelForm.illustrate"
                  type="textarea"
                  placeholder="请输入"
                  :maxlength="500"
                  resize="none"
                  :autosize="{ minRows: 2 }"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="handelType === '3'">
            <el-col :span="24">
              <el-form-item label="请选择责任人办理" prop="responsible" required>
                <el-select v-model="handelForm.responsible" clearable>
                  <el-option
                    v-for="item in responsibleList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="附件" prop="annex">
                <upload-multiple-file
                  v-model="handelForm.annex"
                  :default-list="defaultList"
                  @get-file-data="getFileData2"
                ></upload-multiple-file>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handelFormChanel">取 消</el-button>
        <el-button type="primary" @click="handelSubmit" :loading="btnLoading">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  addAsDraft,
  addAndSubmit,
  submitAsDraft,
  getOrgTree,
  userTree,
  responsibleList,
  OAhandle,
  OAgoBack,
  OAclose,
  taskDetail
} from '@/api/general-user/OA';
import { validateRules } from './validateRules.js';
import UploadMultipleFile from 'components/Upload/UploadMultipleFile';
import { downloadFile } from '@/api/general-user/internal-data.js';
import { exportFun } from '@/utils/export.js';

export default {
  mixins: [validateRules],
  components: { UploadMultipleFile },
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    /* 传递的数据 */
    info: {
      type: Object,
      required: true,
      default: {}
    },
    OAtype: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      /* 显隐 */
      show: this.dialogVisible,
      /* 显示数据 */
      ruleForm: {},
      handelForm: {},
      /* 机构用户树 */
      orgList: [],
      userList: [],
      /* 确认按钮状态 */
      btnLoading: false,
      defaultList: [],
      /* 自身机构 */
      selfOrg: {},
      dialogLoading: false,
      /* 时间选取今天以前 */
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 1;
        },
        shortcuts: [
          {
            text: '一小时',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 1);
              picker.$emit('pick', date);
            }
          },
          {
            text: '12小时',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 12);
              picker.$emit('pick', date);
            }
          },
          {
            text: '一天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 1);
              picker.$emit('pick', date);
            }
          },
          {
            text: '一周',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }
        ]
      },
      id: '',
      sysFileInfos: [],
      defaultTime: '',
      rules: {},
      todorules: {
        illustrate: [{ required: true, message: '请输入办理说明', trigger: '' }],
        responsible: [{ required: true, message: '请选择责任人', trigger: '' }]
      },
      treeOption: {
        multiple: true,
        checkStrictly: true,
        emitPath: false,
        label: 'name',
        value: 'id',
        disabled: 'isUser'
      },
      innerVisible: false,
      handelType: '',
      responsibleList: []
    };
  },
  methods: {
    getNow() {
      if (Date.parse(this.ruleForm.timeLimit) < Date.now()) {
        this.rules.timeLimit.push({ max: 5, message: '请选择当前时间之后' }); //max:5 为了触发验证
      } else {
        if (this.rules.timeLimit.length > 1) {
          this.rules.timeLimit.pop();
        }
      }
    },
    /* 父级传递的值 */
    initFormDetail(val) {
      this.resetRuleform();
      val.remark = ''; //解决赋值后remark为undefineBUG
      this.ruleForm = val;
      this.id = val.id; //该数据ID
      this.taskDetail();
    },
    /* 下载附件 */
    uploadFile(fileId) {
      downloadFile({ fileId })
        .then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
            exportFun(res);
            this.$message({
              type: 'success',
              message: '下载数据成功'
            });
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    /* 下载多个附件 */
    uploadFilemore(fileList) {
      fileList.forEach(item => {
        downloadFile({ fileId: item.id })
          .then(res => {
            if (res.status === 200) {
              this.exportLoading = false;
              exportFun(res);
            }
          })
          .catch(() => {
            this.exportLoading = false;
            this.$message({
              type: 'success',
              message: '下载数据成功'
            });
          });
      });
    },
    /* 退出重置 */
    handelClose() {
      this.defaultList = [];
      this.resetRuleform();
      this.$refs.ruleForm.resetFields();
      this.handelChanel();
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate(); //清除验证提醒
      });
    },
    /* 退出重置 */
    handelClose2() {
      this.defaultList = [];
      this.handelForm = {};
      this.$refs.handelForm.resetFields();
      this.innerVisible = false;
      this.$nextTick(() => {
        this.$refs.handelForm.clearValidate();
      });
    },
    /* 关闭弹出框 */
    handelChanel() {
      this.$emit('update:dialogVisible', false);
    },
    handelFormChanel() {
      this.innerVisible = false;
    },
    /* 新增或修改 */
    handelsure() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.addAndSubmit(); //新增提交、草稿提交
        }
      });
    },
    /* 新增方法 */
    addAndSubmit() {
      this.btnLoading = true;
      this.arrToStr();
      addAndSubmit(this.ruleForm)
        .then(res => {
          this.btnLoading = false;
          this.$message.success('新增成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handelSuccess', [true, false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 修改接口调用*/
    submitAsDraft() {
      this.ruleForm.id = this.id;
      this.btnLoading = true;
    },
    /* addAsDraft存为草稿 */
    addAsDraft() {
      this.btnLoading = true;
      this.arrToStr();
      addAsDraft(this.ruleForm)
        .then(res => {
          this.btnLoading = false;
          this.$message.success('保存草稿成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handelSuccess', [true, false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 重置 */
    resetRuleform() {
      this.ruleForm = {};
    },
    /* 获取机构树 */
    getOrgTree() {
      getOrgTree().then(res => {
        this.orgList = res.data;
      });
    },
    //递归
    getIsUser(val) {
      val.forEach(item => {
        item.isUser = item.isUser === '';
        if (item.children) {
          this.getIsUser(item.children);
        } else {
          return;
        }
      });
    },
    /* 获取责任人树 */
    userTree() {
      userTree().then(res => {
        this.userList = res.data;
        this.getIsUser(this.userList);
      });
    },
    /* 数组转字符串 */
    arrToStr() {
      if (this.ruleForm.responsibleUnit) {
        this.ruleForm.responsibleUnit = this.ruleForm.responsibleUnit.join();
      }
      if (this.ruleForm.responsible) {
        this.ruleForm.responsible = this.ruleForm.responsible.join();
      }
    },
    /* 上传附件处理 */
    getFileData(fileIdList) {
      if (this.ruleForm.annex) {
        this.ruleForm.annex = fileIdList.join(',');
      }
    },
    getFileData2(fileIdList) {
      if (this.handelForm.annex) {
        this.handelForm.annex = fileIdList.join(',');
      }
    },
    getFileDetail(fileIdList) {
      console.log(fileIdList);
      this.ruleForm.annexList.push(fileIdList);
      this.$forceUpdate();
    },
    /* 调用详情接口 */
    taskDetail() {
      this.dialogLoading = true;
      taskDetail({ id: this.id })
        .then(res => {
          var { records, annexList, remark } = res.data;
          this.dialogLoading = false;
          this.ruleForm.records = records;
          this.ruleForm.annexList = annexList;
          if (remark.length) {
            this.ruleForm.remark = remark;
          }
          this.$forceUpdate();
        })
        .catch(() => {
          this.dialogLoading = false;
        });
    },
    //删除附件
    deleteAnnex(val) {
      this.$confirm('请确认是否删除该附件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {});
    },
    /* 办理 */
    handelSubmit() {
      this.btnLoading = true;
      this.handelForm.belongAssigned = this.id;
      var requestArr = [OAhandle, OAclose, OAgoBack];
      var successArr = ['办理成功', '办结成功', '回退成功'];
      requestArr[Number(this.handelType) - 1](this.handelForm)
        .then(res => {
          this.$message.success(successArr[Number(this.handelType) - 1]);
          this.innerVisible = false;
          this.$emit('handelSuccess', [true, false]);
          this.btnLoading = false;
        })
        .catch(err => {
          this.btnLoading = false;
        });
    },
    /* 办理、办结弹窗显隐 */
    handeltodo() {
      this.$emit('update:dialogVisible', false);
      this.innerVisible = true;
      this.handelType = '1';
    },
    /* 办结 */
    handelTaskClose() {
      this.$emit('update:dialogVisible', false);
      this.innerVisible = true;
      this.handelType = '2';
    },
    /* 退回 */
    handelreturn() {
      this.$emit('update:dialogVisible', false);
      this.innerVisible = true;
      this.handelType = '3';
      this.dialogLoading = true;
      responsibleList({ id: this.id })
        .then(res => {
          this.responsibleList = res.data;
          this.dialogLoading = false;
        })
        .catch(err => {
          this.dialogLoading = false;
        });
    },
    /* 操作类型 */
    transOperational(val) {
      if (val === 0) {
        return "<span style='color:green'>上报</span>";
      } else if (val === 1) {
        return "<span style='color:red'>退回</span>";
      } else {
        return "<span style='color:blue'>办结</span>";
      }
    }
  },
  created() {
    this.getOrgTree();
    this.userTree();
  },
  watch: {
    dialogTitle(val) {
      if (val.includes('新增')) {
        this.resetRuleform();
      }
    },
    dialogVisible(val) {
      this.show = val;
    }
  },
  computed: {
    //是否为已办页面
    OAbtnshow() {
      if (this.OAtype === '1' || this.OAtype === '3') {
        return false;
      } else {
        return true;
      }
    },
    //是否为详情
    isRead() {
      if (this.dialogTitle.includes('新增')) {
        return false;
      } else if (this.dialogTitle.includes('草稿')) {
        return false;
      } else {
        return true;
      }
    },
    //当前状态
    statuType() {
      if (this.ruleForm.status) {
        return this.ruleForm.status === 1;
      }
    }
  }
};
</script>
<style scpoed>
.taskInfoP {
  background-color: aliceblue;
  font-weight: bold;
  font-size: 20px;
  padding: 5px;
}
.deletewarning {
  color: red;
}
</style>
