export const validateRules = {
  data() {
    var validateOrgId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入工作内容'));
      }else if (value.length > 500) {
        return callback(new Error('最大字符为500'));
      }
      callback();
    };
    var validatePost = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择办结时限'));
      }
      callback();
    };
    var validateProblem = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择责任单位'));
      }
      callback();
    };
    var validateSuggestion = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择责任人'));
      } 
      callback();
    };

    return {
      rules: {
        content: [{ validator: validateOrgId, trigger: '' }],
        timeLimit: [{ validator: validatePost, trigger: '' }],
        responsibleUnit: [{ validator: validateProblem, trigger: '' }],
        responsible: [{ validator: validateSuggestion, trigger: '' }],
      }
    };
  }
};
