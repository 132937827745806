<template>
  <div>
    <!-- 列表搜索 -->
    <el-form :inline="true" :model="pages" class="demo-form-inline">
      <el-form-item label="办结时限">
        <el-date-picker
          v-model="finishDate"
          type="daterange"
          clearable
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="完成情况" v-if="OAtype != '2'">
        <el-select v-model="pages.completion" placeholder="请选择完成情况" clearable collapse-tags>
          <el-option label="草稿" value="0"></el-option>
          <el-option label="待办理" value="1"></el-option>
          <el-option label="已办理" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetInput">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 功能区按钮 -->
    <div>
      <el-button v-if="$check(OAadd)" type="primary" @click="addInfo" icon="el-icon-plus">新增事项</el-button>
    </div>
    <!-- 显示列表 -->
    <el-table ref="multipleTable" :data="tableData" stripe style="width: 100%" v-loading="tabelLoading">
      <el-table-column label="序号" align="center" width="55" fixed>
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="degreeLevel" label="缓急程度" align="center" width="180" fixed sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.completion === 1" v-html="scope.row.degreeLevel"></span>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="工作内容" align="center" width="150" show-overflow-tooltip sortable>
      </el-table-column>
      <el-table-column
        prop="responsibleUnitName"
        label="责任单位"
        align="center"
        width="150"
        show-overflow-tooltip
        sortable
      >
      </el-table-column>
      <el-table-column prop="responsibleName" label="责任人" align="center" show-overflow-tooltip sortable>
      </el-table-column>
      <el-table-column prop="completion" label="完成情况" align="center" width="100" show-overflow-tooltip sortable>
        <template slot-scope="scope">
          <span v-html="transCompletion(scope.row.completion)"></span>
        </template>
      </el-table-column>
      <el-table-column prop="timeLimit" label="办结时限" align="center" show-overflow-tooltip sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.timeLimit.slice(0, scope.row.timeLimit.length - 3) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="currentManagerName" label="当前办理人" align="center" show-overflow-tooltip sortable>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150" fixed="right">
        <template slot-scope="scope">
          <span v-if="$check(OAedit)" class="action-btn" style="margin:0 10px" @click="handleDetails(scope.row)"
            >详情</span
          >
          <span
            v-if="$check(OAdelete)"
            class="action-btn deletewarning"
            style="margin:0 10px"
            @click="handleDelete(scope.row.id)"
            >删除</span
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.pageNo"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pages.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 详情、新增、修改框 -->
    <list-dialog
      ref="formRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :info="dialogData"
      @handelSuccess="handelSuccess"
      v-bind="$attrs"
    ></list-dialog>
  </div>
</template>
<script>
import { pageLedger, pageTodo, pageDone, taskDetail, taskDelete } from '@/api/general-user/OA';
import listDialog from './listDialog.vue';
import { cloneDeep } from 'lodash';
export default {
  components: { listDialog },
  inheritAttrs: false, //用$attrs作为中间层 false取消在DOM显示绑定的参数。
  data() {
    return {
      /* 数据 */
      tableData: [],
      total: 0,
      tabelLoading: false,
      /* 分页、搜索栏 */
      pages: {
        pageNo: 1,
        pageSize: 10
      },
      finishDate: [],
      /* 回显数据 */
      dialogVisible: false,
      dialogTitle: '',
      dialogData: {}
    };
  },
  methods: {
    /* 搜索 */
    onSubmit() {
      this.pages.pageNo = 1;
      this.pages.pageSize = 10;
      this.pageLedger();
    },
    /* 获取数据列表 */
    pageLedger() {
      this.tabelLoading = true;
      var requesrArr = [pageLedger, pageTodo, pageDone];
      requesrArr[Number(this.OAtype)-1](this.pages).then(res => {
        this.tabelLoading = false;
        this.tableData = res.data.rows;
        this.total = res.data.totalRows;
      });
    },
    /* 分页大小 */
    handleSizeChange(val) {
      this.pages.pageNo = 1; //重置为第一页
      this.pages.pageSize = val;
      this.pageLedger();
    },
    /* 分页当前页 */
    handleCurrentChange(val) {
      this.pages.pageNo = val;
      this.pageLedger();
    },
    /* 重置搜索框 */
    resetInput() {
      this.$refs.multipleTable.clearSort();
      this.finishDate = [];
      this.pages = {};
      this.pageLedger();
    },
    /* 弹出框显隐 */
    DialogShow() {
      this.dialogVisible = true;
    },
    /* 新增 */
    addInfo() {
      this.dialogTitle = '新增';
      this.DialogShow();
    },
    /* 查看详情 */
    handleDetails(row) {
      if (row.completion === 0) {
        this.dialogTitle = '草稿';
      } else {
        this.dialogTitle = '查看详情';
      }
      this.DialogShow();
      const obj = cloneDeep(row);
      obj.responsibleUnit = obj.responsibleUnit.split(',');
      obj.responsible = obj.responsible.split(',');
      this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
    },
    /* 删除 */
    handleDelete(id) {
      this.$confirm('请确认是否删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.taskDelete(id);
      });
    },
    /* 删除接口 */
    taskDelete(id) {
      taskDelete({ id }).then(res => {
          this.$message.success('删除成功');
          if (this.tableData.length === 1 && this.pages.pageNo > 1) {
            this.pages.pageNo = this.pages.pageNo - 1; //删除一页的最后一条时，跳转前一页（大于1页）
          }
          this.pageLedger();
      });
    },
    /* 操作回调 */
    handelSuccess(arr) {
      //arr[0]为返回列表第一页，否则为编辑，在当前页
      //arr[1]为是否是删除操作
      console.log(arr);
      if (arr[0]) {
        this.pages.pageNo = 1;
      }
      if (arr[1]) {
        if (this.tableData.length === 1 && this.pages.pageNo > 1) {
          this.pages.pageNo = this.pages.pageNo - 1; //删除一页的最后一条时，跳转前一页（大于1页）
        }
      }
      this.pageLedger();
      //刷新列表
    },
    transCompletion(val) {
      if (val === 0) {
        return '<span style="color:#F79818">草稿</span>';
      } else if (val === 1) {
        return '<span style="color:#FF6C00">待办理</span>';
      } else {
        return '<span style="color:#2FBF3F">已办结</span>';
      }
    }
  },
  created() {
    this.pageLedger();
  },
  watch: {
    finishDate(val) {
      this.pages.startTime = val[0];
      this.pages.endTime = val[1];
    }
  },
  computed: {
    OAadd() {
      if (this.OAtype === '1') {
        return 'OA-leader-task-add';
      } else if (this.OAtype === '2') {
        return 'OA-task-todo-add';
      } else if (this.OAtype === '3') {
        return 'OA-task-done-add';
      } else {
        return false;
      }
    },
    OAedit() {
      if (this.OAtype === '1') {
        return 'OA-leader-task-edit';
      } else if (this.OAtype === '2') {
        return 'OA-task-todo-edit';
      } else if (this.OAtype === '3') {
        return 'OA-task-done-edit';
      } else {
        return false;
      }
    },
    OAdelete() {
      if (this.OAtype === '1') {
        return 'OA-leader-task-delete';
      } else if (this.OAtype === '2') {
        return 'OA-task-todo-delete';
      } else if (this.OAtype === '3') {
        return 'OA-task-done-delete';
      } else {
        return false;
      }
    },
    OAtype() {
      return this.$attrs.OAtype;
    }
  }
};
</script>
<style lang="less" scoped>
.btn {
  margin-left: 50px;
}
.deletewarning {
  color: red;
}
</style>
